import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import CategoryGrid from "../../components/CategoryGrid";
import BlogRoll from "../../components/BlogRoll";

const TagsPage = ({
  data: {
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <Helmet title={`Tags | ${title}`} />
    <div title="Post Categories" className="pb-8 px-4 md:px-16 lg:px-64">
      <CategoryGrid />
    </div>
    <div title="Recent Posts" className="py-8 md:py-20 px-4 md:px-16 xl:px-32">
      <h2 className="text-2xl font-poppins pb-8">Recently Posted</h2>
      <BlogRoll limit={1000} />
    </div>
  </Layout>
);

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
